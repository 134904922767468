<!-- <div class="cc1">
    <div class="header">
        @if(!data.unsubscribe) {
        <span class="cc2">Registrieren</span>
        } @else {
        <span class="cc2">Vom Newsletter abmelden</span>
        }
        <span class="cc2 clicable" (click)="onNoClick()">
            <mat-icon aria-hidden="true" fontIcon="close"></mat-icon>
        </span>
    </div>
    @if(!data.unsubscribe) {
    @if(!registrationsended) {
    <div class="cc3">
        <span>
            Willkommen! <br> <br>

            wir freuen uns, dass unser Newsletter Ihr Interesse geweckt hat. Wir möchten Sie mit wöchentlichen
            E-Mails
            zu attraktiven Reisezielen, aktuellen Trends und Empfehlungen, sowie ausgewählten Top-Angeboten
            begeistern.
            Gerne würden wir Sie mit Ihrem Namen persönlich ansprechen – bitte tragen Sie dazu einfach zusätzlich
            Ihren
            Namen im Formular ein. Die Büroauswahl dient der Festlegung des Reisebüros das Ihnen den
            Newsletter zuschicken darf.
        </span>

        <form [formGroup]="registrationform">
            <div class="cc4">
                <div class="cc5">
                    <div>
                        <mat-button-toggle-group formControlName="salutation" name="salutation">
                            <mat-button-toggle value="Herr">Herr</mat-button-toggle>
                            <mat-button-toggle value="Frau">Frau</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="cc6">
                    <mat-form-field appearance="fill">
                        <mat-label>Vorname</mat-label>
                        <input matInput type="text" placeholder="Vorname" formControlName="firstname" name="firstname">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Nachname</mat-label>
                        <input matInput type="text" placeholder="Nachname" formControlName="lastname" name="lastname">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" placeholder="Email" formControlName="email" name="email" required>
                    </mat-form-field>
                    <mat-checkbox formControlName="check" name="check"
                        [style.background-color]="registrationform.controls['check'].invalid && registrationform.controls['check'].touched ? '#ffc5c5': ''">
                        * Ja, ich möchte den Newsletter abonnieren</mat-checkbox>
                </div>
            </div>
        </form>

        <span>
            <br>
            Der Schutz Ihrer Daten ist uns sehr wichtig. Daher erhalten Sie nach Ihrer Registrierung eine
            Bestätigungsmail an die angegeben Mail-Adresse. Selbstverständlich können Sie Ihre Einwilligung
            jederzeit mündlich, schriftlich oder über einen Abmeldelink im jeweiligen Newsletter widerrufen!
            <br>
            Bitte beachten Sie unsere <span class="cc7" (click)="gotodataschutz($event)">Datenschutzhinweise</span>!
        </span>
    </div>

    <ng-container *ngIf="error?.length > 0">
        <div>
            <span>{{error}}</span>
        </div>
    </ng-container>

    <div class="actionnew">
        <button mat-button class="cc11" (click)="onNoClick()" *ngIf="!loading else myloading">Abrechen</button>
        <button mat-flat-button class="c10" (click)="registrierenF()" [disabled]="loading">Registrieren</button>
    </div>
    } @else {
    <div class="cc9">
        <span class="cc13">E-Mail wurde versendet.</span>
        <span class="cc14">Newsletter Bestätigungs-Mail wurde versandt</span>
        <span>
            Es wird eine e-Mail an Ihre Adresse {{email}} versendet. Bitte bestätigen Sie die Anmeldung duch den Link in
            der e-Mail.
            Sollten Sie keine e-Mail erhalten haben, prüfen Sie bitte den Spam-Ordner Ihres Postfaches.
        </span>
    </div>

    <div class="actionnew">
        <button mat-button class="cc8" (click)="onNoClick()">Schließen</button>
    </div>
    }
    } @else {
    @if(!unregistrationsended) {
    <div class="cc3">
        <span>
            Hier können Sie sich vom Newsletter abmelden. <br> <br>
        </span>

        <form [formGroup]="unregistrationform">
            <div class="cc4">
                <div class="cc6">
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" placeholder="Email" formControlName="email" name="email" required>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <ng-container *ngIf="error?.length > 0">
        <div>
            <span>{{error}}</span>
        </div>
    </ng-container>

    <div class="actionnew">
        <button mat-button class="cc11" (click)="onNoClick()" *ngIf="!loading else myloading">Abrechen</button>
        <button mat-flat-button class="c10" color="primary" (click)="unregistrierenF()" [disabled]="loading">ja, vom
            Newsletter abmelden</button>
    </div>
    } @else {
    <div class="cc9">
        <span class="cc13">Sie wurden vom Newsletter abgemeldet. Aus technischen Gründen kann es sein, dass Sie in den nächsten Stunden dennoch noch einen Newsletter erhalten. Wir bitten dies zu entschuldigen.</span>
    </div>

    <div class="actionnew">
        <button mat-button class="cc8" (click)="onNoClick()">Schließen</button>
    </div>
    }
    }


</div>

<ng-template #myloading>
    <mat-spinner diameter="20"></mat-spinner>
</ng-template> -->

@if(unregistrationform && registrationform) {
    <div class="ccc1">
        <div [class.ccc2]="!smallMode" [class.ccc2m]="smallMode">
    
            <div class="ccc13">
                <img [src]="logo" class="imglogo">
            </div>
    
            @if(!data.unsubscribe) {
            @if(!registrationsended) {
            <div class="ccc14">
                <div class="ccc15">
                    <span class="ccc3">Sichern Sie sich 100 Euro für Ihre nächste Reise!</span>
                </div>
    
                <div class="ccc15">
                    <span class="ccc4">Tragen Sie sich in unseren Newsletter ein und erhalten Sie einen Reisegutschein im
                        Wert von 100 Euro als Dankeschön!</span>
                </div>
                <form [formGroup]="registrationform">
                    <div class="ccc5">
                        <span class="ccc4">Name</span>
                        <input type="text" class="ccc6" formControlName="firstname" name="firstname">
                    </div>
    
                    <div class="ccc5">
                        <span class="ccc4">E-Mail Adresse *</span>
                        <input type="text" formControlName="email" name="email" class="ccc6">
                    </div>
                </form>
                <div class="ccc7">
                    <button (click)="setChecked()">
                        @if(registrationform.value.check) {
                        <mat-icon>check_box</mat-icon>
                        } @else {
                        <mat-icon>check_box_outline_blank</mat-icon>
                        }
                    </button>
                    <span class="ccc4 ccc10">* Ja ich möchte regelmäßig über Angebote zum Thema Urlaub und Reisen von
                        {{partner.firmenname}}
                        informiert werden und akzeptiere die <span class="cc7"
                            (click)="gotodataschutz($event)">Datenschutzerklärung</span>.
                    </span>
                </div>
    
                @if(!registrationform.valid && registrationform.touched) {
                <div class="errorf">
                    <span>* Mussfelder</span>
                </div>
                }
    
                <div class="ccc8">
                    <button class="ccc9" (click)="registrierenF()" [disabled]="loading"> Jetzt anmelden und Gutschein erhalten!</button>
                </div>
            </div>
            } @else {
            <div class="cc9">
                <span class="ccc3">Newsletter Bestätigungs-Mail wurde versandt</span>
                <span class="ccc4">
                    Es wird eine e-Mail an Ihre Adresse {{email}} versendet. Bitte bestätigen Sie die Anmeldung duch den
                    Link in
                    der e-Mail.
                    Sollten Sie keine e-Mail erhalten haben, prüfen Sie bitte den Spam-Ordner Ihres Postfaches.
                </span>
            </div>
    
            <div class="ccc8">
                <button class="ccc9" (click)="onNoClick()">Schließen</button>
            </div>
            }
            } @else {
            @if(!unregistrationsended) {
            <div class="ccc14">
                <div class="ccc15">
                    <span class="ccc3">Hier können Sie sich vom Newsletter abmelden.</span>
                </div>
                <form [formGroup]="unregistrationform">
                    <div class="ccc5">
                        <span class="ccc4">E-Mail Adresse *</span>
                        <input type="text" class="ccc6" formControlName="email" name="email">
                    </div>
                </form>
            </div>
    
            <div class="ccc8">
                <button class="ccc9" (click)="unregistrierenF()" [disabled]="loading">ja, vomNewsletter abmelden</button>
            </div>
            } @else {
            <div class="cc9">
                <span class="ccc4">Sie wurden vom Newsletter abgemeldet. Aus technischen Gründen kann es sein, dass Sie in
                    den
                    nächsten Stunden dennoch noch einen Newsletter erhalten. Wir bitten dies zu entschuldigen.</span>
            </div>
    
            <div class="ccc8">
                <button class="ccc9" (click)="onNoClick()">Schließen</button>
            </div>
            }
            }
        </div>
        @if(!smallMode) {
        <div class="ccc12">
            <img src="../../../../assets/images/newsletterimg.jpg">
        </div>
        }
        @if(error?.length > 0) {
        <div>
            <span>{{error}}</span>
        </div>
        }
        <button [class.ccc16m]="smallMode" [class.ccc16]="!smallMode"  (click)="onNoClick()">
            <mat-icon class="ccc17">close</mat-icon>
        </button>
    </div>
    }